<div class="section-submited">
  <mat-card *ngIf="loading">
    <div class="risk-report-title-box-2">
      <h1 class="risk-report-title-box-2__title">Loading Items</h1>
    </div>
  </mat-card>

  <div class="container-mat-tab">
    <nav class="risk-nav-main">
      <ul class="risk-nav-list">
        <li>
          <a class="risk-nav-link" routerLink="/">Initiatives</a>
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            [routerLink]="[
              '/',
              'initiative',
              initiativeId,
              officalCode,
              'submission'
            ]"
            class="risk-nav-link"
            ><span class="line">&nbsp;</span>{{ officalCode }}</a
          >
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            [routerLink]="[
              '/',
              'initiative',
              initiativeId,
              officalCode,
              'submited-versions'
            ]"
            class="risk-nav-link"
            >Submitted versions</a
          >
        </li>
      </ul>
    </nav>
    <mat-card *ngIf="!loading">
      <div class="risk-report-title-box">
        <h1 class="risk-report-title-box__title">
          One CGIAR Initiatives Plan of Results and Budget Template
        </h1>
      </div>

      <div class="form-container-4">
        <form class="search-4">
          <div>
            <mat-card-subtitle class="card-title-3 m-b-t"
              >{{ initiative_data.official_code }} ‐
              {{ initiative_data.name }}</mat-card-subtitle
            >
          </div>
          <div *ngIf="this.submission_data?.toc_version">
            <mat-card-subtitle class="card-title-3 m-b-t"
              >Toc version ID: {{this.submission_data?.toc_version}}</mat-card-subtitle
            >
          </div>
        </form>
      </div>
      <!-- <mat-card-title class="card-title"
        ><span class="card-title"
          >One CGIAR Initiatives Plan of Results and Budget Template</span
        ></mat-card-title
      > -->
      <!-- <mat-card-subtitle class="card-title"
        ><span class="card-title"
          >{{ initiative_data.official_code }}‐{{ initiative_data.name }}</span
        ></mat-card-subtitle
      > -->
    </mat-card>

    <!-- <mat-card *ngIf="loading">
      <mat-card-title>Loading</mat-card-title>
      <mat-card-subtitle>Preparing Items</mat-card-subtitle>
    </mat-card> -->

    <mat-tab-group *ngIf="!loading && this.partners?.length">
      <mat-tab label="Summary">
        <mat-card>
          <mat-card-title class="card-title-table">Consolidated</mat-card-title>
          <mat-card-content>
            <div class="table-box-6 mat-elevation-z8">
              <table>
                <tr>
                  <th>Results</th>
                  <ng-container *ngFor="let item of period">
                    <th>{{ item.year }} - {{ item.quarter }}</th>
                  </ng-container>

                  <th>Budget Percentage</th>
                  <th>Budget (USD)</th>
                </tr>
                <ng-container *ngFor="let wp of wps">
                  <tr>
                    <td class="wp-title">{{ wp.title }}</td>
                    <ng-container *ngFor="let item of period">
                      <td>
                        <mat-icon
                          class="icon-sub-table"
                          *ngIf="
                            perValuesSammary[wp.ost_wp.wp_official_code][
                              item.id
                            ] == true
                          "
                          >close</mat-icon
                        >
                      </td>
                    </ng-container>
                    <td>
                      {{
                        sammaryTotalConsolidated[wp.ost_wp.wp_official_code]
                          | number : "1.2-2"
                      }}%
                    </td>
                    <td>
                      {{
                        roundNumber(
                          summaryBudgetsTotal[wp.ost_wp.wp_official_code]
                        ) | number 
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr class="total">
                  <td><span class="subtotal">Total</span></td>
                  <ng-container *ngFor="let item of period">
                    <td>
                      <mat-icon
                        class="icon-sub-table-2"
                        color="primary"
                        *ngIf="finalPeriodVal(item.id)"
                        >close</mat-icon
                      >
                    </td>
                  </ng-container>
                  <td>{{ wpsTotalSum | number : "1.2-2" }}%</td>
                  <td>{{ roundNumber(summaryBudgetsAllTotal) | number }}</td>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
        <ng-container *ngFor="let wp of wps">
          <div class="flex-box-2">
            <mat-card *ngIf="allData[wp.ost_wp.wp_official_code]">
              <mat-card-title class="card-title-table">{{
                wp.title
              }}</mat-card-title>
              <mat-slide-toggle
                [(ngModel)]="toggleSummaryValues[wp.ost_wp.wp_official_code]"
                (toggleChange)="
                  toggleSummaryActualValues(wp.ost_wp.wp_official_code)
                "
                [ngClass]="{
                  'show-d': wp.id != 'CROSS' && wp.id != 'IPSR',
                  'show-d-2': wp.id == 'CROSS',
                  'show-d-3': wp.id == 'IPSR'
                }"
                >Show decimals</mat-slide-toggle
              >
              <mat-card-content>
                <div class="table-box mat-elevation-z8">
                  <table>
                    <tr>
                      <th>WP/Results</th>
                      <th>Type</th>
                      <ng-container *ngFor="let item of period">
                        <th>{{ item.year }} - {{ item.quarter }}</th>
                      </ng-container>
                      <th>Budget Percentage</th>
                      <th>Budget (USD)</th>
                    </tr>
                    <ng-container
                      *ngFor="let item of allData[wp.ost_wp.wp_official_code]"
                    >
                      <tr>
                        <td class="wp-title">
                          <ng-container
                            *ngIf="item?.initiativeMelia?.meliaType?.name"
                          >
                            {{ item?.initiativeMelia?.meliaType.name }}
                          </ng-container>
                          <ng-container
                            *ngIf="!item?.initiativeMelia?.meliaType?.name"
                          >
                            {{
                              item?.ipsr?.id
                                ? item?.ipsr.title + " (" + item.value + ")"
                                : item.title
                            }}
                          </ng-container>
                        </td>
                        <td>{{ item.category }}</td>
                        <ng-container *ngFor="let per of period">
                          <td>
                            <!-- to be checked -->
                            <mat-icon
                              class="icon-sub-table"
                              *ngIf="
                                perAllValues[wp.ost_wp.wp_official_code][
                                  item.id
                                ][per.id] == true
                              "
                              >close</mat-icon
                            >
                          </td>
                        </ng-container>
                        <td>
                          {{
                            toggleSummaryValues[wp.ost_wp.wp_official_code]
                              ? sammary[wp.ost_wp.wp_official_code][item.id]
                              : roundNumber(
                                  sammary[wp.ost_wp.wp_official_code][item.id]
                                )
                          }}%
                        </td>
                        <td>
                          {{
                            toggleSummaryValues[wp.ost_wp.wp_official_code]
                              ? (summaryBudgets[wp.ost_wp.wp_official_code][
                                  item.id
                                ] | number) 
                              : roundNumber(
                                  summaryBudgets[wp.ost_wp.wp_official_code][
                                    item.id
                                  ]
                                ) | number 
                          }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr class="total">
                      <td colspan="2">
                        <span class="subtotal">Subtotal</span>
                      </td>
                      <ng-container *ngFor="let per of period">
                        <td>
                          <mat-icon
                            class="icon-sub-table-2"
                            color="primary"
                            *ngIf="
                              finalItemPeriodVal(
                                wp.ost_wp.wp_official_code,
                                per.id
                              )
                            "
                            >close</mat-icon
                          >
                        </td>
                      </ng-container>

                      <td>
                        {{
                          toggleSummaryValues[wp.ost_wp.wp_official_code]
                            ? sammaryTotal[wp.ost_wp.wp_official_code]
                            : roundNumber(
                                sammaryTotal[wp.ost_wp.wp_official_code]
                              )
                        }}%
                      </td>

                      <td>
                        {{
                          toggleSummaryValues[wp.ost_wp.wp_official_code]
                            ? (summaryBudgetsTotal[wp.ost_wp.wp_official_code] | number) 
                            : roundNumber(
                                summaryBudgetsTotal[wp.ost_wp.wp_official_code]
                              ) | number 
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab
        *ngFor="let partner of partners | sort : 'asc' : 'acronym'"
        [label]="partner?.acronym ? partner?.acronym : partner?.name"
      >




      <mat-card>
        <mat-card-title class="card-title-table-Consolidated">
          Consolidated
        </mat-card-title>

        <mat-card-content>
          <div class="table-box mat-elevation-z8">
            <table>
              <tr>
                <th>Results</th>
                <ng-container *ngFor="let item of period">
                  <th>{{ item.year }} - {{ item.quarter }}</th>
                </ng-container>

                <th>Budget Percentage</th>
                <th>Budget (USD)</th>
              </tr>
              <ng-container *ngFor="let wp of wps">
                <tr>
                  <td class="wp-title">{{ wp.title }}</td>
                  <ng-container *ngFor="let item of period">
                    <td>
                      <mat-icon
                        class="icon-sub-table-2"
                        color="primary"
                        *ngIf="
                        perValuesSammaryForPartner[partner.code][wp.ost_wp.wp_official_code][
                            item.id
                          ] == true
                        "
                        >close</mat-icon
                      >
                    </td>
                  </ng-container>
                  <td>
                    {{ getPercentageForeachPartnerWp(wp_budgets[partner.code],
                      wp_budgets[partner.code][
                     wp.ost_wp.wp_official_code]) || 0 | number : "1.2-2"}}%
                  </td>
                  <td>
                    {{
                      roundNumber(wp_budgets[partner.code][
                      wp.ost_wp.wp_official_code]) | number
                    }}
                  </td>
                </tr>
              </ng-container>
              <tr class="total">
                <td><div class="subtotal">Total</div></td>
                <ng-container *ngFor="let item of period">
                  <td>
                    <mat-icon
                      class="icon-sub-table-2"
                      color="primary"
                      *ngIf="finalPeriodValForPartner(partner.code , item.id)"
                      >close</mat-icon
                    >
                  </td>
                </ng-container>
                <td>
                  {{ getTotalPercentageForEachPartner(wp_budgets[partner.code]) || 0 | number : "1.2-2"}}%
                </td>
                <td>
                  {{getTotalBudgetForEachPartner(wp_budgets[partner.code]) }}
                </td>
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>


        <ng-container *ngFor="let wp of wps">
          <mat-card
            *ngIf="
              partnersData[partner.code] &&
              partnersData[partner.code][wp.ost_wp.wp_official_code]
            "
          >
            <mat-card-title class="card-title-table">{{
              wp.title
            }}</mat-card-title>
            <mat-slide-toggle
              [(ngModel)]="
                toggleValues[partner.code][wp.ost_wp.wp_official_code]
              "
              (toggleChange)="
                toggleActualValues(partner.code, wp.ost_wp.wp_official_code)
              "
              [ngClass]="{
                'show-d': wp.id != 'CROSS' && wp.id != 'IPSR',
                'show-d-2': wp.id == 'CROSS',
                'show-d-3': wp.id == 'IPSR'
              }"
              >Show decimals</mat-slide-toggle
            >
            <mat-card-content>
              <div class="table-box mat-elevation-z8">
                <table
                  [ngClass]="{
                    error: errors[partner.code][wp.ost_wp.wp_official_code]
                  }"
                >
                  <tr>
                    <th>WP/Results</th>
                    <th>Type</th>
                    <ng-container *ngFor="let item of period">
                      <th>{{ item.year }} - {{ item.quarter }}</th>
                    </ng-container>
                    <th>Percentage %</th>
                    <th>Budget</th>
                  </tr>

                  <ng-container
                    *ngFor="
                      let item of partnersData[partner.code][
                        wp.ost_wp.wp_official_code
                      ]
                    "
                  >
                    <tr>
                      <td class="wp-title">
                        <ng-container
                          *ngIf="item?.initiativeMelia?.meliaType?.name"
                        >
                          {{ item?.initiativeMelia?.meliaType.name }}
                        </ng-container>
                        <ng-container
                          *ngIf="!item?.initiativeMelia?.meliaType?.name"
                        >
                          {{
                            item?.ipsr?.id
                              ? item?.ipsr.title + " (" + item.value + ")"
                              : item.title
                          }}
                        </ng-container>
                      </td>
                      <td>{{ item.category }}</td>
                      <ng-container *ngFor="let per of period">
                        <td>
                          <mat-icon
                            class="icon-sub-table"
                            *ngIf="
                              perValues[partner.code][
                                wp.ost_wp.wp_official_code
                              ][item.id][per.id] == true
                            "
                            >close</mat-icon
                          >
                        </td>
                      </ng-container>
                      <td>
                        {{
                          toggleValues[partner.code][wp.ost_wp.wp_official_code]
                            ? values[partner.code][wp.ost_wp.wp_official_code][
                                item.id
                              ]
                            : displayValues[partner.code][
                                wp.ost_wp.wp_official_code
                              ][item.id]
                        }}%
                      </td>
                      <td>
                        {{
                          toggleValues[partner.code][wp.ost_wp.wp_official_code]
                            ? (budgetValues[partner.code][
                                wp.ost_wp.wp_official_code
                              ][item.id] | number )
                            : displayBudgetValues[partner.code][
                                wp.ost_wp.wp_official_code
                              ][item.id] | number 
                        }}
                      </td>
                    </tr>
                  </ng-container>
                  <tr class="total">
                    <td [colSpan]="2">
                      <span class="subtotal">Subtotal</span>
                    </td>

                    <ng-container *ngFor="let per of period">
                      <td>
                        <mat-icon
                          class="icon-sub-table-2"
                          color="primary"
                          *ngIf="
                            finalCenterItemPeriodVal(
                              partner.code,
                              wp.ost_wp.wp_official_code,
                              per.id
                            )
                          "
                          >close</mat-icon
                        >
                      </td>
                    </ng-container>
                    <td>
                      {{
                        toggleValues[partner.code][wp.ost_wp.wp_official_code]
                          ? totals[partner.code][wp.ost_wp.wp_official_code]
                          : roundNumber(
                              totals[partner.code][wp.ost_wp.wp_official_code]
                            )
                      }}%
                    </td>
                    <td>
                      {{ wp_budgets[partner.code][wp.ost_wp.wp_official_code] | number  }}
                    </td>
                  </tr>
                </table>
              </div>
              <p class="error">
                {{ errors[partner.code][wp.ost_wp.wp_official_code] }}
              </p>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
