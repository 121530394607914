<div class="container">
  <h1 >
    {{title}}
  </h1>
  
  <div >
    <p>{{message}}</p>
  </div>
  
  <div >
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>
</div>
