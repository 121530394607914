<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">Assign Organizations</p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <mat-dialog-content>
    <form [formGroup]="assignOrgsForm" class="form-dialog-content">
      <div>
        <div class="form-dialog-conten-button-box">
          <div class="form-dialog-conten__button-box" align="end">
            <!-- <button mat-button mat-dialog-close>Cancel</button> -->
            <div class="risk-report-button-box">
              <button
                class="risk-report-button-box__btn btn--chestnut"
                type="submit"
                mat-raised-button
                mat-button
                color="primary"
                mat-button
                color="primary"
                (click)="submit()"
              >
                <mat-icon class="create-risk">
                  <span class="material-symbols-outlined"> data_saver_on </span>
                </mat-icon>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- //Organizations  filed----- -->

      <div class="form-dialog-content__field--grid-column">
        <div class="form-dialog-content__title-box">
          <mat-label class="form-dialog-content__title-box__title"
            >Organizations<span class="star">*</span></mat-label
          >
        </div>
        <div class="kk">
          <ng-select
            [appendTo]="'.kk'"
            [items]="organizations | sort : 'asc' : 'acronym'"
            bindValue="code"
            appearance="outline"
            bindLabel="name"
            [multiple]="true"
            [closeOnSelect]="false"
            formControlName="organizations"
            placeholder="Organizations"
          >
            <ng-template ng-optgroup-tmp ng-label-tmp let-item="item">
              <span>{{ item.acronym }}</span> -
              <span>{{ item.name }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span>{{ item.acronym }}</span> -
              <span>{{ item.name }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <!-- <mat-select
      formControlName="organizations"
      multiple
      [compareWith]="compareOrganization"
    >

  
      
      <mat-option
        *ngFor="let organization of organizations | orderSelectPipe"
        [value]="organization"
      >
        {{ organization.name }}
      </mat-option>
    </mat-select> -->
      <!-- 
      <div class="form-dialog-content__field--grid-column">
        <div class="form-dialog-content__title-box">
          <mat-label class="form-dialog-content__title-box__title"
            >Organizations<span class="star">*</span></mat-label
          >
        </div>
        <mat-form-field
          class="form-dialog-content__field"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline"
        >
          <mat-select
            formControlName="organizations"
            multiple
            [(ngModel)]="selectedFood"
            (ngModelChange)="kk()"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip
                  *ngFor="
                    let organization of assignOrgsForm.value.organizations
                  "
                  [removable]="true"
                  (removed)="onToppingRemoved(organization)"
                >
                  <strong class="official-color">{{ k }}</strong>

                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-listbox>
            </mat-select-trigger>

            <mat-option
              [value]="organization.code"
              *ngFor="let organization of organizations"
            >
              {{ organization.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </form>
  </mat-dialog-content>
</div>

<!-- <mat-dialog-actions align="end"> -->
<!-- <button mat-button mat-dialog-close>Cancel</button> -->
<!-- </mat-dialog-actions> -->
