<!-- <app-chat></app-chat> -->
<div class="section-risk-report-component">
  <div class="team-members-container">
    <nav class="risk-nav-main">
      <ul class="risk-nav-list">
        <li>
          <a class="risk-nav-link" routerLink="/">Initiatives</a>
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            [routerLink]="[
              '/',
              'initiative',
              initiativeId,
              officalCode,
              'submission'
            ]"
            class="risk-nav-link"
            ><span class="line">&nbsp;</span>{{ officalCode }}</a
          >
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a routerLink="./" class="risk-nav-link">Submitted versions</a>
        </li>
      </ul>
    </nav>
    <!-- 
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Mia"
        #input
      />
    </mat-form-field> -->

    <div class="team-member-content-box">
      <!-- step-1 -->
      <div class="team-members-text-box m-b">
        <p class="team-members-text-box__text">Submitted versions</p>
      </div>

      <app-filter-version (filters)="filter($event)"></app-filter-version>

      <div class="table-box-4 mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="th-3">ID</span>
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.id }}
            </td>
          </ng-container>

          <!-- phase -->
          <ng-container matColumnDef="phase">
            <th mat-header-cell *matHeaderCellDef>Phase</th>
            <td mat-cell *matCellDef="let row">{{ row.phase.name }}</td>
          </ng-container>

          <!-- created_by -->
          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef>Created by</th>
            <td mat-cell *matCellDef="let row">{{ row.user.full_name }}</td>
          </ng-container>

          <!-- created_at -->
          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>Created on</th>
            <td mat-cell *matCellDef="let row">{{ row.created_at | date }}</td>
          </ng-container>

          <!-- status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">{{ row.status }}</td>
          </ng-container>
          <!-- status_reason -->
          <ng-container matColumnDef="status_reason">
            <th mat-header-cell *matHeaderCellDef>Status Reason</th>
            <td
              mat-cell
              *matCellDef="let row"
              [innerHTML]="row.status_reason"
            ></td>
          </ng-container>

          <ng-container matColumnDef="toc_version_id">
            <th mat-header-cell *matHeaderCellDef>Toc version ID</th>
            <td mat-cell *matCellDef="let row">{{ row.toc_version ? row.toc_version : 'N/A' }}</td>
          </ng-container>

          <!-- actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row; first as isFirst">
              <div class="m">
                <button
                  class="team-members-icon-table"
                  matTooltip="View"
                  matTooltipPosition="above"
                  routerLink="./{{ row['id'] }}"
                >
                  <mat-icon class="user-management-table__icon"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#403f3f"
                        d="M12 16q1.875 0 3.188-1.313T16.5 11.5q0-1.875-1.313-3.188T12 7q-1.875 0-3.188 1.313T7.5 11.5q0 1.875 1.313 3.188T12 16Zm0-1.8q-1.125 0-1.913-.788T9.3 11.5q0-1.125.788-1.913T12 8.8q1.125 0 1.913.788T14.7 11.5q0 1.125-.787 1.913T12 14.2Zm0 4.8q-3.65 0-6.65-2.038T1 11.5q1.35-3.425 4.35-5.463T12 4q3.65 0 6.65 2.038T23 11.5q-1.35 3.425-4.35 5.463T12 19Z"
                      /></svg
                  ></mat-icon>
                </button>

                <button
                  class="team-members-icon-table"
                  *ngIf="(row.status == 'Pending' && user.role == 'admin') || (row.status == 'Approved' && user.role == 'admin' && isFirst)"
                  matTooltip="Change Status"
                  matTooltipPosition="above"
                  (click)="changeStatus(row)"
                >
                  <mat-icon class="user-management-table__icon"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#403f3f"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.06 17v-2.01H12c-1.28 0-2.56-.49-3.54-1.46a5.006 5.006 0 0 1-.64-6.29l1.1 1.1c-.71 1.33-.53 3.01.59 4.13c.7.7 1.62 1.03 2.54 1.01v-2.14l2.83 2.83L12.06 19zm4.11-4.24l-1.1-1.1c.71-1.33.53-3.01-.59-4.13A3.482 3.482 0 0 0 12 8.5h-.06v2.15L9.11 7.83L11.94 5v2.02c1.3-.02 2.61.45 3.6 1.45c1.7 1.7 1.91 4.35.63 6.29z"
                      /></svg
                  ></mat-icon>
                </button>

                <button
                  *ngIf="row.status == 'Approved'"
                  class="team-members-icon-table"
                  matTooltip="Download"
                  matTooltipPosition="above"
                  (click)="generatePDF(row.id)"
                >
                  <mat-icon
                    class="user-management-table__icon"
                    style="color: #403f3f"
                    >picture_as_pdf</mat-icon
                  >
                </button>

                <button
                  *ngIf="row.status == 'Approved'"
                  class="team-members-icon-table"
                  matTooltip="Download"
                  matTooltipPosition="above"
                  (click)="generateExcel(row.id)"
                >
                  <mat-icon
                    class="user-management-table__icon"
                    style="color: #403f3f"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"
                      />
                    </svg>
                  </mat-icon>
                </button>
                <mat-icon
                  *ngIf="isAllowedToAccessChat"
                  class="user-management-table__icon"
                  style="color: #403f3f"
                  (click)="openChatDialog(row.initiative_id, row.id)"
                >
                  chat
                </mat-icon>
              </div>
            </td>
          </ng-container>

          <tr
            class="table-header"
            mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            class="table-header"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ last: this.dataSource.data[0] == row }"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <!-- <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr> -->
        </table>
        <div class="glossary-content-container user-management-paginator-box">
          <mat-paginator
            [pageSizeOptions]="[10, 20, 30, 50, 100]"
            [length]="length"
            [pageIndex]="pageIndex - 1"
            (page)="pagination($event)"
            aria-label="Select page of users"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="margin" style="margin-top: 30000px" *ngIf="toPdf">
  <div
    class="PDF"
    #pdfcontent
    id="pdfcontent"
    style="font-family: Balsamiq-Sans, serif; letter-spacing: 0.01px"
  >
    <div class="container-mat-tab">
      <mat-card>

        <div class="risk-report-title-box">
          <h1 class="risk-report-title-box__title">
            One CGIAR Initiatives Plan of Results and Budget Template
          </h1>
        </div>

        <div class="form-container-4">
          <form class="search-4">
            <div>
              <mat-card-subtitle class="card-title-3 m-b-t"
                >{{ initiative_data.official_code }} ‐
                {{ initiative_data.name }}</mat-card-subtitle
              >
            </div>

            <div>
              <mat-card-subtitle class="card-title-3 m-b-t">
                <span class="card-title-3 m-b-t"
                  >Version ID ({{ submission_data?.id }}) - Created At ({{
                    submission_data?.created_at | date
                  }})</span
                >
              </mat-card-subtitle>
            </div>

            <div class="card-title-3 m-b-t">
              {{ this.submission_data?.phase.name }}
            </div>
          </form>
        </div>
      </mat-card>
      <mat-card *ngIf="loading">
        <mat-card-title>Loading</mat-card-title>
        <mat-card-subtitle>Preparing Items</mat-card-subtitle>
      </mat-card>

      <div *ngIf="!loading">
        <!-- <mat-tab label="Summary"> -->
        <mat-card>
          <mat-card-title class="card-title-table">Consolidated</mat-card-title>
          <mat-card-content>
            <div class="table-box mat-elevation-z8">
              <table>
                <tr>
                  <th>Results</th>
                  <ng-container *ngFor="let item of period">
                    <th>{{ item.year }} - {{ item.quarter }}</th>
                  </ng-container>

                  <th>Percentage %</th>
                  <th>Budget (USD)</th>
                </tr>
                <ng-container *ngFor="let wp of wps">
                  <tr>
                    <td class="wp-title">{{ wp.title }}</td>
                    <ng-container *ngFor="let item of period">
                      <td>
                        <span
                          class="icon-X"
                          *ngIf="
                            perValuesSammary[wp.ost_wp.wp_official_code][
                              item.id
                            ] == true && toPdf
                          "
                        >
                          X
                        </span>
                        <mat-icon
                          class="icon-sub-table"
                          *ngIf="
                            perValuesSammary[wp.ost_wp.wp_official_code][
                              item.id
                            ] == true && !toPdf
                          "
                          >close</mat-icon
                        >
                      </td>
                    </ng-container>
                    <td>
                      {{
                        sammaryTotalConsolidated[wp.ost_wp.wp_official_code]
                          | number : "1.2-2"
                      }}%
                    </td>
                    <td>
                      {{
                        roundNumber(
                          summaryBudgetsTotal[wp.ost_wp.wp_official_code]
                        ) | number 
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr class="total">
                  <td><span class="subtotal">Total</span></td>
                  <ng-container *ngFor="let item of period">
                    <td>
                      <span
                        class="icon-X-2"
                        color="primary"
                        *ngIf="finalPeriodVal(item.id) && toPdf"
                      >
                        X
                      </span>
                      <mat-icon
                        class="icon-sub-table-2"
                        color="primary"
                        *ngIf="finalPeriodVal(item.id) && !toPdf"
                        >close</mat-icon
                      >
                    </td>
                  </ng-container>
                  <td
                    style="
                      font-family: Balsamiq-Sans, serif;
                      letter-spacing: 0.01px;
                    "
                  >
                    {{ wpsTotalSum | number : "1.2-2" }}%
                  </td>
                  <td>{{ roundNumber(summaryBudgetsAllTotal) | number  }}</td>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>

        <ng-container *ngFor="let wp of wps">
          <div class="flex-box-2">
            <mat-card *ngIf="allData[wp.ost_wp.wp_official_code]">
              <mat-card-title class="card-title-table">{{
                wp.title
              }}</mat-card-title>
              <mat-card-content>
                <div class="table-box-2 mat-elevation-z8">
                  <table>
                    <tr>
                      <th>WP/Results</th>
                      <th>Type</th>
                      <ng-container *ngFor="let item of period">
                        <th>{{ item.year }} - {{ item.quarter }}</th>
                      </ng-container>
                      <th>Percentage %</th>
                      <th>Budget</th>
                    </tr>
                    <ng-container
                      *ngFor="let item of allData[wp.ost_wp.wp_official_code]"
                    >
                      <tr>
                        <td class="wp-title">
                          <ng-container
                            *ngIf="item?.initiativeMelia?.meliaType?.name"
                          >
                            {{ item?.initiativeMelia?.meliaType.name }}
                          </ng-container>
                          <ng-container
                            *ngIf="!item?.initiativeMelia?.meliaType?.name"
                          >
                            {{
                              item?.ipsr?.id
                                ? item?.ipsr.title + " (" + item.value + ")"
                                : item.title
                            }}
                          </ng-container>
                        </td>
                        <td>{{ item.category }}</td>
                        <ng-container *ngFor="let per of period">
                          <td>
                            <!-- to be checked -->
                            <span
                              class="icon-X"
                              *ngIf="
                                perAllValues[wp.ost_wp.wp_official_code][
                                  item.id
                                ][per.id] == true && toPdf
                              "
                            >
                              X
                            </span>
                            <mat-icon
                              class="icon-sub-table"
                              *ngIf="
                                perAllValues[wp.ost_wp.wp_official_code][
                                  item.id
                                ][per.id] == true && !toPdf
                              "
                              >close</mat-icon
                            >
                          </td>
                        </ng-container>
                        <td>
                          {{
                            toggleSummaryValues[wp.ost_wp.wp_official_code]
                              ? sammary[wp.ost_wp.wp_official_code][item.id]
                              : roundNumber(
                                  sammary[wp.ost_wp.wp_official_code][item.id]
                                )
                          }}%
                        </td>
                        <td>
                          {{
                            toggleSummaryValues[wp.ost_wp.wp_official_code]
                              ? summaryBudgets[wp.ost_wp.wp_official_code][
                                  item.id
                                ]
                              : roundNumber(
                                  summaryBudgets[wp.ost_wp.wp_official_code][
                                    item.id
                                  ]
                                ) | number 
                          }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr class="total">
                      <td colspan="2">
                        <span class="subtotal">Subtotal</span>
                      </td>
                      <ng-container *ngFor="let per of period">
                        <td>
                          <span
                            class="icon-X-2"
                            color="primary"
                            *ngIf="
                              finalItemPeriodVal(
                                wp.ost_wp.wp_official_code,
                                per.id
                              ) && toPdf
                            "
                          >
                            X
                          </span>
                          <mat-icon
                            class="icon-sub-table-2"
                            color="primary"
                            *ngIf="
                              finalItemPeriodVal(
                                wp.ost_wp.wp_official_code,
                                per.id
                              ) && !toPdf
                            "
                            >close</mat-icon
                          >
                        </td>
                      </ng-container>

                      <td>
                        {{
                          toggleSummaryValues[wp.ost_wp.wp_official_code]
                            ? sammaryTotal[wp.ost_wp.wp_official_code]
                            : roundNumber(
                                sammaryTotal[wp.ost_wp.wp_official_code]
                              )
                        }}%
                      </td>

                      <td>
                        {{
                          toggleSummaryValues[wp.ost_wp.wp_official_code]
                            ? summaryBudgetsTotal[wp.ost_wp.wp_official_code]
                            : roundNumber(
                                summaryBudgetsTotal[wp.ost_wp.wp_official_code]
                              ) | number 
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>

        <!-- </mat-tab> -->
        <ng-container
          *ngFor="let partner of partners | sort : 'asc' : 'acronym'"
        >
          <mat-card-subtitle class="acronymtext m-b-t"
            >{{ partner?.name }} <span>-</span>
            {{ partner?.acronym }}</mat-card-subtitle
          >


        <mat-card>
          <mat-card-title class="card-title-table">
            Consolidated
          </mat-card-title>

          <mat-card-content>
            <div class="table-box mat-elevation-z8">
              <table>
                <tr>
                  <th>Results</th>
                  <ng-container *ngFor="let item of period">
                    <th>{{ item.year }} - {{ item.quarter }}</th>
                  </ng-container>

                  <th>Budget Percentage</th>
                  <th>Budget (USD)</th>
                </tr>
                <ng-container *ngFor="let wp of wps">
                  <tr>
                    <td class="wp-title">{{ wp.title }}</td>
                    <ng-container *ngFor="let item of period">
                      <td>
                        <span
                          class="icon-sub-table-2"
                          color="primary"
                          *ngIf="
                          perValuesSammaryForPartner[partner.code][wp.ost_wp.wp_official_code][
                              item.id
                            ] == true
                          "
                          >X</span
                        >
                      </td>
                    </ng-container>
                    <td>
                      {{ getPercentageForeachPartnerWp(wp_budgets[partner.code],
                        wp_budgets[partner.code][
                       wp.ost_wp.wp_official_code]) || 0 | number : "1.2-2"}}%
                    </td>
                    <td>
                      {{
                        roundNumber(wp_budgets[partner.code][
                        wp.ost_wp.wp_official_code]) | number
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr class="total">
                  <td><div class="subtotal">Total</div></td>
                  <ng-container *ngFor="let item of period">
                    <td>
                      <span
                        class="icon-sub-table-2"
                        color="primary"
                        *ngIf="finalPeriodValForPartner(partner.code , item.id)"
                        >X</span
                      >
                    </td>
                  </ng-container>
                  <td>
                    {{ getTotalPercentageForEachPartner(wp_budgets[partner.code]) || 0 | number : "1.2-2" }}%
                  </td>
                  <td>
                    {{getTotalBudgetForEachPartner(wp_budgets[partner.code]) }}
                  </td>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>


          <ng-container *ngFor="let wp of wps">
            <mat-card
              *ngIf="
                partnersData[partner.code] &&
                partnersData[partner.code][wp.ost_wp.wp_official_code]
              "
            >
              <mat-card-title class="card-title-table">{{
                wp.title
              }}</mat-card-title>
              <mat-card-content>
                <div class="table-box-2 mat-elevation-z8">
                  <table
                    [ngClass]="{
                      error: errors[partner.code][wp.ost_wp.wp_official_code]
                    }"
                  >
                    <tr>
                      <th>WP/Results</th>
                      <th>Type</th>
                      <ng-container *ngFor="let item of period">
                        <th>{{ item.year }} - {{ item.quarter }}</th>
                      </ng-container>
                      <th>Percentage %</th>
                      <th>Budget</th>
                    </tr>

                    <ng-container
                      *ngFor="
                        let item of partnersData[partner.code][
                          wp.ost_wp.wp_official_code
                        ]
                      "
                    >
                      <tr>
                        <td class="wp-title">
                          <ng-container
                            *ngIf="item?.initiativeMelia?.meliaType?.name"
                          >
                            {{ item?.initiativeMelia?.meliaType.name }}
                          </ng-container>
                          <ng-container
                            *ngIf="!item?.initiativeMelia?.meliaType?.name"
                          >
                            {{
                              item?.ipsr?.id
                                ? item?.ipsr.title + " (" + item.value + ")"
                                : item.title
                            }}
                          </ng-container>
                        </td>
                        <td>{{ item.category }}</td>
                        <ng-container *ngFor="let per of period">
                          <td>
                            <span
                              class="icon-X"
                              *ngIf="
                                perValues[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id][per.id] == true && toPdf
                              "
                            >
                              X
                            </span>
                            <mat-icon
                              class="icon-sub-table"
                              *ngIf="
                                perValues[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id][per.id] == true && !toPdf
                              "
                              >close</mat-icon
                            >
                          </td>
                        </ng-container>
                        <td>
                          {{
                            toggleValues[partner.code][
                              wp.ost_wp.wp_official_code
                            ]
                              ? values[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id]
                              : displayValues[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id]
                          }}%
                        </td>
                        <td>
                          {{
                            toggleValues[partner.code][
                              wp.ost_wp.wp_official_code
                            ]
                              ? budgetValues[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id]
                              : displayBudgetValues[partner.code][
                                  wp.ost_wp.wp_official_code
                                ][item.id] | number 
                          }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr class="total">
                      <td [colSpan]="2">
                        <span class="subtotal">Subtotal</span>
                      </td>
                      <ng-container *ngFor="let per of period">
                        <td>
                          <span
                            class="icon-sub-table-2"
                            color="primary"
                            *ngIf="
                              finalCenterItemPeriodVal(
                                partner.code,
                                wp.ost_wp.wp_official_code,
                                per.id
                              )
                            "
                            >X</span
                          >
                        </td>
                      </ng-container>
                      <td>
                        {{ 
                          toggleValues[partner.code][wp.ost_wp.wp_official_code]
                            ? totals[partner.code][wp.ost_wp.wp_official_code]
                            : roundNumber(
                                totals[partner.code][wp.ost_wp.wp_official_code]
                              )
                        }}%
                      </td>
                      <td>
                        {{ 
                          wp_budgets[partner.code][wp.ost_wp.wp_official_code] | number 
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
                <p class="error">
                  {{ errors[partner.code][wp.ost_wp.wp_official_code] }}
                </p>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
