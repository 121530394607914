<section class="section-variety-tools">
  <div class="under__variety-tools">
    <div class="variety-tools__title-box">
      <!-- <h2 class="variety-tools__title-box--title">Variety of Tools</h2> -->
    </div>

    <div class="variety-tools__content">
      <div class="variety-tools-el tools-e1">
        <div class="variety-tools__logo-box">
          <!-- <img
            src="../../assets/shared-image/Reporting-tool.svg"
            class="variety-tools__logo-box__logo"
            alt="~/Frontend/images/Reporting-tool.svg"
          /> -->
        </div>
        <div class="variety-tools__text-box">
          <p
            *ngFor="let item of constants"
            class="variety-tools__text-box__text"
            [innerHTML]="item.value"
          ></p>
          <!-- <p class="variety-tools__text-box__text">Kind regards,</p>
          <p class="variety-tools__text-box__text">D&D team</p>
          <p class="variety-tools__text-box__text">
            For user support kindly contact
            <a
              class="footer-link"
              href="mailto:projectcoordinationunit@cgiar.org"
            >
              prmstechsupport@cgiar.org &rarr;</a
            >
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>
