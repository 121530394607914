<section class="section-risk-management-component">
  <div class="risk-management-container">
    <div class="risk-management-content">
      <app-search-init (filters)="filter($event)"> </app-search-init>

      <div class="table-box">
        <div class="table-box mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">ID</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{ row.id }}</span>
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="official_code">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Official code</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{ row.official_code }}</span>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Name</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{ row.name }}</span>
              </td>
            </ng-container>

            <!-- Short Column -->
            <ng-container matColumnDef="short_name">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Short Name</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{ row.short_name }}</span>
              </td>
            </ng-container>
            <!-- my_role Column -->
            <ng-container matColumnDef="my_role">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">My role</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{ myRoles(row.roles) }}</span>
              </td>
            </ng-container>

            <!-- Last update on Column -->
            <ng-container matColumnDef="last_update_at">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Last update on</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{
                  row.last_update_at | date
                }}</span>
              </td>
            </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Current Status</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-s-c-linear">{{
                  row.last_submitted_at != null &&
                  row.last_update_at == row.last_submitted_at
                    ? row?.latest_submission
                      ? row?.latest_submission?.status
                      : "Draft"
                    : "Draft"
                }}</span>
              </td>
            </ng-container>

            <!-- actions -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-color-linear">Actions</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <div class="actions">

                     <button
                    class="color-icons-table-action"
                    matTooltip="Submit your center activites"
                    matTooltipPosition="above"
                    *ngIf="isContributor(row.roles) && user.role == 'user'"
                    routerLink="initiative/{{ row['id'] }}/{{
                      row['official_code']
                    }}/submission"
                    mat-icon-button
                  >
                    <mat-icon>playlist_add_check</mat-icon>
                  </button>


                      <!-- 1 -->
                      <button
                      class="color-icons-table-action"
                      *ngIf="isCoLeader(row.roles) || isLeader(row.roles) || isCoordinator(row.roles)"
                      mat-icon-button
                      (click)="openDialog(row.id)"
                      matTooltip="Assign Organizations"
                      matTooltipPosition="above"
                    >
                      <mat-icon>dns</mat-icon>
                    </button>



     <!-- 2 -->
     <!-- <button
     class="color-icons-table-action"
     matTooltip="Manage Initiative MELIA"
     matTooltipPosition="above"
     *ngIf="isLeader(row.roles) || isCoordinator(row.roles)"
     routerLink="initiative/{{ row['id'] }}/{{
       row['official_code']
     }}/melia"
     mat-icon-button
   >
     <mat-icon class="melia-icon">
       <img  src="../../assets/shared-image/Manage-initiative-MELIA.png" alt="Manage-initiative-MELIA-icon">
     </mat-icon>
   </button> -->
          
   
       <!-- 3 -->
                  
       <button
       class="color-icons-table-action"
       matTooltip="Complete the PORB"
       matTooltipPosition="above"
       *ngIf="isCoLeader(row.roles) || isLeader(row.roles) || isCoordinator(row.roles)"
       routerLink="initiative/{{ row['id'] }}/{{
         row['official_code']
       }}/submission"
       mat-icon-button
     >
       <mat-icon>playlist_add</mat-icon>
     </button>
   

                      <!-- 4 -->
                  <button
                  class="color-icons-table-action"
                  matTooltip="Manage Initiative Team"
                  matTooltipPosition="above"
                  *ngIf="isCoLeader(row.roles) || isLeader(row.roles) || isCoordinator(row.roles) || isContributor(row.roles)"
                  routerLink="initiative/{{ row['id'] }}/{{
                    row['official_code']
                  }}/team-members"
                  mat-icon-button
                >
                  <mat-icon>group</mat-icon>
                </button>

                       <!-- 5 -->
                  <button
                  class="color-icons-table-action"
                  matTooltip="View Submited versions"
                  matTooltipPosition="above"
                  routerLink="initiative/{{ row['id'] }}/{{
                    row['official_code']
                  }}/submited-versions"
                  mat-icon-button
                >
                  <mat-icon>view_carousel</mat-icon>
                </button>


                


                  


               


               


              

                  
               

             

               
                </div>
              </td>
            </ng-container>

            <tr
              class="table-header"
              mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              class="table-header"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8">
                No results matching({{
                  allfilters?.name ||
                    allfilters?.my_role ||
                    allfilters?.initiative_id ||
                    allfilters?.status
                }})
              </td>
            </tr>
          </table>
          <div class="glossary-content-container user-management-paginator-box">
            <mat-paginator
              [pageSizeOptions]="[10, 20, 30, 50, 100]"
              [length]="length"
              [pageIndex]="pageIndex - 1"
              (page)="pagination($event)"
              aria-label="Select page of users"
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
