<!-- <div class="form-container">
  <form class="search">
    <div class="sec-7"></div>

    <div>
      <p>
        {{ initiative_data.name }} ‐
        {{ initiative_data.short_name }}
      </p>
    </div>
  </form>
</div> -->
<div>
  <div>
    <!-- <mat-card-subtitle class="card-title"
      >{{ organization?.name }} ‐ {{ organization?.acronym }}</mat-card-subtitle
    > -->

    <button
      class="risk-report-button-box__btn--4 btn--white-2"
      mat-raised-button
      (click)="complete()"
      type="button"
      [disabled]="isDisabled"
      [ngClass]="isDisabled ? 'disabled' : 'btn'"
    >
      Mark as {{ status ? "" : "In" }}complete
    </button>
  </div>
</div>

<mat-icon
  matTooltipPosition="above"
  *ngIf="status === false"
  class="text-if-complete"
  matTooltip="If you need to make any change to this center, please mark it as
  incomplete first"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_14774_17883)">
      <circle cx="10" cy="10" r="9" fill="white" />
      <path
        d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
        fill="#dc3545"
      />
    </g>
    <defs>
      <clipPath id="clip0_14774_17883">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</mat-icon>

<!-- <sat-popover #popoverCheckbox hasBackdrop>
  <div class="popover">
    <div class="popover__head">
      <p *ngIf="status === false">
        If you need to make any change to this center, please mark it as
        incomplete first
      </p>
    
    </div>
  </div>
</sat-popover> -->
