<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ userId ? "Edit" : "Add" }} User
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<!-- <h1 mat-dialog-title>{{ userId ? "Edit" : "Add" }} User</h1> -->
<div class="form-dialog-conten-box-1">
  <form [formGroup]="userForm" class="form-dialog-content">
    <!-- //Email filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Email<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="email" />
        <!-- <mat-error
          class="error"
          *ngIf="userForm.controls?.['email']?.errors?.['required']"
          >email is required</mat-error
        > -->
        <mat-error
          class="error"
          *ngIf="userForm.controls?.['email']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
        <mat-error
          class="error"
          *ngIf="userForm.controls?.['email']?.errors?.['email']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //First name filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name<span class="star">*</span></mat-label
        >
      </div>

      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="first_name" />
        <mat-error
          class="error"
          *ngIf="userForm.controls?.['first_name']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Last name filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="last_name" />

        <mat-error
          class="error"
          *ngIf="userForm.controls?.['last_name']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Role  filed----- -->
    <div class="form-dialog-conten-box-2">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Role<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select formControlName="role">
          <mat-option value="admin">Admin</mat-option>
          <mat-option value="user">User</mat-option>
        </mat-select>

        <mat-error
          class="error"
          *ngIf="userForm.controls?.['role']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box" align="end">
          <!-- <button mat-button mat-dialog-close>Cancel</button> -->
          <div class="risk-report-button-box">
            <button
              class="risk-report-button-box__btn btn--chestnut"
              type="submit"
              mat-raised-button
              mat-button
              color="primary"
              (click)="submit()"
            >
              {{ userId ? "Save" : "Add" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
