import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-spinner',
  templateUrl: './chat-spinner.component.html',
  styleUrls: ['./chat-spinner.component.scss']
})
export class ChatSpinnerComponent {

}
