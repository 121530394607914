<div class="track-porbs__icon-box">
  <mat-icon class="track-porbs__icon-box__icon" style="font-size: 3rem;">
    monetization_on
  </mat-icon>
  <span class="track-porbs__icon-box__title">Budget Summary</span>
</div>

<div class="track-porbs__text-box--1">
  <p class="track-porbs__text-box__title">
    Welcome to the Budget Summary panel, here you can manage the Budget Summary table
    within the platform.
  </p>
</div>


<!-- 
<div class="sec-5">
  <div class="risk-management-form-field field-3">
    <mat-label class="risk-management-form-field__label"
      >Role</mat-label
    >

    <mat-select
      formControlName="role"
      class="risk-management-form-field-box__input-3"
      [placeholder]="'All'"
      id="select-where"
    >
      <mat-option>All</mat-option>
      <mat-option value="user">User</mat-option>
      <mat-option value="admin">Admin</mat-option>
    </mat-select>
  </div>
</div> -->

<div class="track-porbs__content--1">
  <form class="search" [formGroup]="filterForm">
    <div class="sec-5">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Phase</mat-label>

        <mat-select formControlName="phase_id" class="risk-management-form-field-box__input-3">
          <mat-option *ngFor="let phase of phases" [value]="phase.id">{{phase.name}}</mat-option>
        </mat-select>
      </div>
    </div>
  </form>

  <div class="table-box mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" id="tableId">



      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <span class="h1 th-1-m">Initiative ID</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.official_code }}</td>
        <td mat-footer-cell *matFooterCellDef colspan="2" style="font-weight: bold;"> Total </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>
          <span>Initiative Title</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight: bold; text-align: center;"> {{ this.total | number}}
        </td>

      </ng-container>

      <ng-container matColumnDef="total_budget">
        <th mat-header-cell *matHeaderCellDef>
          <span>Initiative Total budget</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{getTotalBudget(element?.submissions[0].wp_budget) | number}}
        </td>
        <td mat-footer-cell *matFooterCellDef style="display: none;"> {{'dodo'}} </td>

      </ng-container>

      <ng-container *ngFor="let partner of organization" [matColumnDef]="partner.acronym">
        <th mat-header-cell *matHeaderCellDef>{{ partner.acronym }}</th>
        <td mat-cell *matCellDef="let element" data-val="0">
          <ng-container *ngFor="let data of element?.submissions[0].wp_budget">
            <ng-container *ngIf="data?.organization?.acronym == partner.acronym">
              {{ (data.organization.acronym === partner.acronym ? data.total : '') | number }}
            </ng-container>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef style="font-weight: bold; text-align: center;"> {{
          getTotalForEachPartner(partner) | number}} </td>
      </ng-container>






      <tr class="table-header" mat-header-row *matHeaderRowDef="newColumnsToDisplay"></tr>
      <tr class="table-header" mat-row *matRowDef="let myRowData; columns: newColumnsToDisplay"></tr>
      <tr mat-footer-row *matFooterRowDef="newColumnsToDisplay; sticky: true"></tr>

    </table>
  </div>
</div>