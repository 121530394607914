<div mat-dialog-container class="delete-box">
  <!-- <div class="form-dialog-header delete-header">
    <div class="form-dialog-header__text-box">
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title == 'Logout'"
      >
        Logout
      </p>
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title != 'Send to all users' || data.title != 'Logout'"
      >
        Delete
      </p>
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title == 'Send to all users'"
      >
        {{ data.title }}
      </p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title == 'Logout'">Logout</p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title != 'Send to all users' && data.title != 'Logout'">Delete</p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title == 'Send to all users'">{{data.title}}</p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button class="form-dialog-header__icon-box__btn" (click)="closeDialog()">
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div> -->

  <div
    class="delete-content-box"
    [style.background]="headerService.backgroundDeleteLr"
  >
    <div class="el-1"></div>
    <div class="el-2">
      <div class="el-2-box">
        <div *ngIf="!data.svg && data.title !='Cancel submission'">
          <img
            class="dialog-img-2"
            src="../../assets/shared-image/delete.png"
            alt=""
          />
        </div>


        <div *ngIf="!data.svg && data.title =='Cancel submission'">
          <mat-icon class="cancel-Submission-icon">cancel_presentation</mat-icon>
        </div>

        
        <div   *ngIf="data.svg">
          <img [style.filter]="data.svg === '../../assets/shared-image/logout.png'? headerService.logoutSvg : '' " class="dialog-img" src="{{ data.svg }}" alt="" />
        </div>

     


       

        <div class="delete-text-box" *ngIf="data?.risks?.length > 0">
          <p class="delete-text-risks">
            This user is assigned as risk owner for risks(
            <strong class="delete-text-box__text-q">
              <!-- {{ risks }} -->
            </strong>
            ). If the user is deleted, make sure to appoint another team member
            for the risk(s) above
          </p>
        </div>
        <div class="delete-text-box" *ngIf="data.message">
          <p class="delete-text-box__text">
            <mat-dialog-content class="delete-text-box__text">
              {{ data.message }}
            </mat-dialog-content>
          </p>
        </div>

        <div class="delete-text-box" *ngIf="data.custom_message_1 && data.custom_message_2">
          <p class="delete-text-box__text">
            <mat-dialog-content class="delete-text-box__text">
              {{ data.custom_message_1 }}
            </mat-dialog-content>
            <mat-dialog-content class="delete-text-box__text">
              {{ data.custom_message_2 }}
            </mat-dialog-content>
          </p>
        </div>
        <div
          *ngIf="data.message2"
          class="delete-text-box-2"
          [ngClass]="{
            'text-3': kk.length === 0
          }"
        >
          <p class="delete-text-box__text-3">
            <mat-dialog-content class="delete-text-box__text--3">
              {{ data.message2 }}
              <div class="incompleteCenters">
                <ol *ngFor="let s of kk" class="delete-text-box__text-3">
                  <li>{{ s }}</li>
                </ol>
              </div>
              <div *ngIf="kk.length">{{ data.k }}</div>
            </mat-dialog-content>
          </p>
        </div>

        <div>
          <mat-dialog-actions>
            <button
              [style.background]="headerService.backgroundDeleteYes"
              class="delete-dialog-button-box__btn"
              mat-raised-button
              [mat-dialog-close]="true"
              cdkFocusInitial
            >
              Yes
            </button>

            <button
              [style.background]="headerService.backgroundDeleteClose"
              class="delete-dialog-button-box__btn--no"
              mat-raised-button
              [mat-dialog-close]="false"
            >
              No
            </button>
          </mat-dialog-actions>
        </div>
      </div>
    </div>
    <div class="el-3"></div>
  </div>

  <!-- <mat-dialog-actions>
    <button
      class="delete-dialog-button-box__btn"
      mat-raised-button
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      Yes
    </button>

    <button
      class="delete-dialog-button-box__btn--no"
      mat-raised-button
      [mat-dialog-close]="false"
    >
      No
    </button>
  </mat-dialog-actions> 
 -->
</div>
