<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">Update submission status</p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <!-- <button mat-dialog-close class="closeButton">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="currentColor"
      class="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
      />
    </svg>
  </button> -->

  <form [formGroup]="statusForm" class="form-dialog-content">
    <!-- //Status  filed----- -->
    <div class="form-dialog-conten-box-2">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Status<span class="star">*</span></mat-label
        >
      </div>

      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select placeholder="Role" formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- //Leave a Reason filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Leave a Reason<span
            class="star"
            *ngIf="statusForm.get('status')?.value === 'Rejected'"
            >*</span
          ></mat-label
        >
      </div>
      <app-editor
        [form]="statusForm"
        [controller]="'status_reason'"
        [showToolbar]="true"
        [withoutUpload]="true"
        [placeholder]="'Ex. It should have...'"
      ></app-editor>
      <mat-error class="error" *ngIf="statusForm?.errors?.['reasonsRequired']"
        >This field is mandatory</mat-error
      >
      <!-- <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea
          matInput
          [required]="statusForm.get('status')?.value === 'Rejected'"
          formControlName="status_reason"
          placeholder="Ex. It should have..."
          class="texta"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
        ></textarea>

        <mat-error
          class="error"
          *ngIf="statusForm.controls?.['status_reason']?.errors?.['required'] && statusForm.get('status')?.value === 'Rejected'"
          >This field is mandatory</mat-error
        >
      </mat-form-field> -->
    </div>

    <!-- //button-section------->

    <div>
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box m-t" align="end">
          <div class="risk-report-button-box">
            <button
              class="risk-report-button-box__btn btn--chestnut"
              type="submit"
              mat-raised-button
              mat-button
              color="primary"
              (click)="submit()"
            >
              <mat-icon class="create-risk">
                <span class="material-symbols-outlined"> data_saver_on </span>
              </mat-icon>
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="end"></div>
</div>
