<div class="section-risk-report-component">
  <div class="team-members-container">
    <nav class="risk-nav-main">
      <ul class="risk-nav-list">
        <li>
          <a class="risk-nav-link" routerLink="/">Initiatives</a>
        </li>
        <li>
          <a routerLink="./" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            [routerLink]="[
              '/',
              'initiative',
              initiativeId,
              officalCode,
              'submission'
            ]"
            class="risk-nav-link"
            ><span class="line">&nbsp;</span>{{ officalCode }}</a
          >
        </li>
        <li>
          <a routerLink="./" class="risk-nav-link">></a>
        </li>
        <li>
          <a routerLink="./" class="risk-nav-link">Team members</a>
        </li>
      </ul>
    </nav>
    <div class="team-member-content-box">
      <!-- step-1 -->
      <div class="team-members-text-box">
        <p class="team-members-text-box__text">Team members</p>
      </div>

      <!-- step-2 -->

      <div class="user-management__search-box-flex">
        <!-- <div class="user-management__search-box">
          <form action="" class="search">
            <input
              class="search__input"
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
              #input
            />
  
            <button
              style="border: none; cursor: pointer; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </form>
        </div> -->
        <div class="risk-report-button-box" *ngIf="canEdit()">
          <button
            class="risk-report-button-box__btn btn--white"
            mat-raised-button
            (click)="openNewTeamMemberDialog()"
          >
            <mat-icon class="create-risk">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M11.5556 0H1.44444C0.642778 0 0 0.65 0 1.44444V11.5556C0 12.35 0.642778 13 1.44444 13H11.5556C12.35 13 13 12.35 13 11.5556V1.44444C13 0.65 12.35 0 11.5556 0ZM11.5556 11.5556H1.44444V1.44444H11.5556V11.5556ZM5.77778 10.1111H7.22222V7.22222H10.1111V5.77778H7.22222V2.88889H5.77778V5.77778H2.88889V7.22222H5.77778V10.1111Z"
                  fill="white"
                />
              </svg>
            </mat-icon>
            Add new member
          </button>
        </div>
      </div>

      <!-- step-3 -->

      <div class="table-box mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="User Name">
            <th mat-header-cell *matHeaderCellDef>User Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element["User Name"] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.user?.email || element.email }}
            </td>
          </ng-container>
          <ng-container matColumnDef="User">
            <th mat-header-cell *matHeaderCellDef>User name</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.user?.full_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Creation Date">
            <th mat-header-cell *matHeaderCellDef>Creation Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date : "MMMM d, y" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element">
              {{ element.role }}
            </td>
          </ng-container>
          <ng-container matColumnDef="organizations">
            <th mat-header-cell *matHeaderCellDef>Granted access</th>
            <td mat-cell *matCellDef="let element">
              {{ join(element.organizations) || "Full access" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.user_id ? "Active" : "Invited" }}
            </td>
          </ng-container>

          <!-- Action Name Column -->

          <ng-container matColumnDef="Actions" stickyEnd *ngIf="canEdit()">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div class="m" *ngIf="user_info.role == 'admin'">
                <button
                  matTooltip="Edit"
                  class="team-members-icon-table"
                  matTooltipPosition="above"
                  (click)="openEditTeamMemberDialog(element.id, element)"
                >
                  <mat-icon class="user-management-table__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M17.6917 8.65312L13.7958 4.97812L15.0792 3.75313C15.4306 3.41771 15.8623 3.25 16.3744 3.25C16.8859 3.25 17.3174 3.41771 17.6687 3.75313L18.9521 4.97812C19.3035 5.31354 19.4868 5.71837 19.5021 6.19262C19.5174 6.66629 19.3493 7.07083 18.9979 7.40625L17.6917 8.65312ZM16.3625 9.94375L6.64583 19.2187H2.75V15.5L12.4667 6.225L16.3625 9.94375Z"
                        fill="#403f3f"
                      /></svg
                  ></mat-icon>
                </button>
                <button
                  matTooltip="Delete"
                  class="team-members-icon-table"
                  matTooltipPosition="above"
                  (click)="deleteMember(element.id)"
                >
                  <mat-icon class="user-management-table__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                    >
                      <path
                        d="M17.7188 4.78125H14.7656V3.79688C14.7635 3.18837 14.5208 2.60541 14.0905 2.17513C13.6602 1.74485 13.0773 1.50216 12.4688 1.5H8.53125C7.92275 1.50216 7.33978 1.74485 6.9095 2.17513C6.47922 2.60541 6.23654 3.18837 6.23438 3.79688V4.78125H3.28125C3.02018 4.78125 2.7698 4.88496 2.58519 5.06957C2.40059 5.25417 2.29688 5.50455 2.29688 5.76562C2.29688 6.0267 2.40059 6.27708 2.58519 6.46168C2.7698 6.64629 3.02018 6.75 3.28125 6.75H3.60938V17.9062C3.61153 18.3407 3.78507 18.7568 4.09229 19.064C4.3995 19.3712 4.81554 19.5447 5.25 19.5469H15.75C16.1845 19.5447 16.6005 19.3712 16.9077 19.064C17.2149 18.7568 17.3885 18.3407 17.3906 17.9062V6.75H17.7188C17.9798 6.75 18.2302 6.64629 18.4148 6.46168C18.5994 6.27708 18.7031 6.0267 18.7031 5.76562C18.7031 5.50455 18.5994 5.25417 18.4148 5.06957C18.2302 4.88496 17.9798 4.78125 17.7188 4.78125ZM8.20312 3.79688C8.20312 3.70985 8.2377 3.62639 8.29923 3.56486C8.36077 3.50332 8.44423 3.46875 8.53125 3.46875H12.4688C12.5558 3.46875 12.6392 3.50332 12.7008 3.56486C12.7623 3.62639 12.7969 3.70985 12.7969 3.79688V4.78125H8.20312V3.79688ZM15.4219 17.5781H5.57812V6.75H15.4219V17.5781ZM9.51562 9.375V14.625C9.51562 14.8861 9.41191 15.1365 9.22731 15.3211C9.0427 15.5057 8.79232 15.6094 8.53125 15.6094C8.27018 15.6094 8.0198 15.5057 7.83519 15.3211C7.65059 15.1365 7.54688 14.8861 7.54688 14.625V9.375C7.54688 9.11393 7.65059 8.86355 7.83519 8.67894C8.0198 8.49434 8.27018 8.39062 8.53125 8.39062C8.79232 8.39062 9.0427 8.49434 9.22731 8.67894C9.41191 8.86355 9.51562 9.11393 9.51562 9.375ZM13.4531 9.375V14.625C13.4531 14.8861 13.3494 15.1365 13.1648 15.3211C12.9802 15.5057 12.7298 15.6094 12.4688 15.6094C12.2077 15.6094 11.9573 15.5057 11.7727 15.3211C11.5881 15.1365 11.4844 14.8861 11.4844 14.625V9.375C11.4844 9.11393 11.5881 8.86355 11.7727 8.67894C11.9573 8.49434 12.2077 8.39062 12.4688 8.39062C12.7298 8.39062 12.9802 8.49434 13.1648 8.67894C13.3494 8.86355 13.4531 9.11393 13.4531 9.375Z"
                        fill="#403f3f"
                      /></svg
                  ></mat-icon>
                </button>
              </div>

              <div class="m" *ngIf="!canEdit()">
                N/A
              </div>
              <div class="m" *ngIf="element.role != 'Leader' && user_info.role !='admin'">
                <button
                matTooltip="Edit"
                class="team-members-icon-table"
                matTooltipPosition="above"
                (click)="openEditTeamMemberDialog(element.id, element)"
              >
                <mat-icon class="user-management-table__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M17.6917 8.65312L13.7958 4.97812L15.0792 3.75313C15.4306 3.41771 15.8623 3.25 16.3744 3.25C16.8859 3.25 17.3174 3.41771 17.6687 3.75313L18.9521 4.97812C19.3035 5.31354 19.4868 5.71837 19.5021 6.19262C19.5174 6.66629 19.3493 7.07083 18.9979 7.40625L17.6917 8.65312ZM16.3625 9.94375L6.64583 19.2187H2.75V15.5L12.4667 6.225L16.3625 9.94375Z"
                      fill="#403f3f"
                    /></svg
                ></mat-icon>
              </button>
              <button
                matTooltip="Delete"
                class="team-members-icon-table"
                matTooltipPosition="above"
                (click)="deleteMember(element.id)"
              >
                <mat-icon class="user-management-table__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                  >
                    <path
                      d="M17.7188 4.78125H14.7656V3.79688C14.7635 3.18837 14.5208 2.60541 14.0905 2.17513C13.6602 1.74485 13.0773 1.50216 12.4688 1.5H8.53125C7.92275 1.50216 7.33978 1.74485 6.9095 2.17513C6.47922 2.60541 6.23654 3.18837 6.23438 3.79688V4.78125H3.28125C3.02018 4.78125 2.7698 4.88496 2.58519 5.06957C2.40059 5.25417 2.29688 5.50455 2.29688 5.76562C2.29688 6.0267 2.40059 6.27708 2.58519 6.46168C2.7698 6.64629 3.02018 6.75 3.28125 6.75H3.60938V17.9062C3.61153 18.3407 3.78507 18.7568 4.09229 19.064C4.3995 19.3712 4.81554 19.5447 5.25 19.5469H15.75C16.1845 19.5447 16.6005 19.3712 16.9077 19.064C17.2149 18.7568 17.3885 18.3407 17.3906 17.9062V6.75H17.7188C17.9798 6.75 18.2302 6.64629 18.4148 6.46168C18.5994 6.27708 18.7031 6.0267 18.7031 5.76562C18.7031 5.50455 18.5994 5.25417 18.4148 5.06957C18.2302 4.88496 17.9798 4.78125 17.7188 4.78125ZM8.20312 3.79688C8.20312 3.70985 8.2377 3.62639 8.29923 3.56486C8.36077 3.50332 8.44423 3.46875 8.53125 3.46875H12.4688C12.5558 3.46875 12.6392 3.50332 12.7008 3.56486C12.7623 3.62639 12.7969 3.70985 12.7969 3.79688V4.78125H8.20312V3.79688ZM15.4219 17.5781H5.57812V6.75H15.4219V17.5781ZM9.51562 9.375V14.625C9.51562 14.8861 9.41191 15.1365 9.22731 15.3211C9.0427 15.5057 8.79232 15.6094 8.53125 15.6094C8.27018 15.6094 8.0198 15.5057 7.83519 15.3211C7.65059 15.1365 7.54688 14.8861 7.54688 14.625V9.375C7.54688 9.11393 7.65059 8.86355 7.83519 8.67894C8.0198 8.49434 8.27018 8.39062 8.53125 8.39062C8.79232 8.39062 9.0427 8.49434 9.22731 8.67894C9.41191 8.86355 9.51562 9.11393 9.51562 9.375ZM13.4531 9.375V14.625C13.4531 14.8861 13.3494 15.1365 13.1648 15.3211C12.9802 15.5057 12.7298 15.6094 12.4688 15.6094C12.2077 15.6094 11.9573 15.5057 11.7727 15.3211C11.5881 15.1365 11.4844 14.8861 11.4844 14.625V9.375C11.4844 9.11393 11.5881 8.86355 11.7727 8.67894C11.9573 8.49434 12.2077 8.39062 12.4688 8.39062C12.7298 8.39062 12.9802 8.49434 13.1648 8.67894C13.3494 8.86355 13.4531 9.11393 13.4531 9.375Z"
                      fill="#403f3f"
                    /></svg
                ></mat-icon>
              </button>
              </div>

              <div class="m" *ngIf="element.role == 'Leader' && user_info.role !='admin'">
                <mat-icon matTooltipPosition="above" matTooltip="Only admin can edit and delete leaders role">help</mat-icon>
              </div>
            </td>
          </ng-container>

          <tr
            class="table-header"
            mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            class="table-header"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>

        <div class="glossary-content-container user-management-paginator-box">
          <mat-paginator
            [pageSizeOptions]="[10, 20, 30, 50, 100]"
            aria-label="Select page of users"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
