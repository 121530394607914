<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ data.id === "add" ? "Add" : "Edit" }} Cross-Cutting item
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <form [formGroup]="crossForm" class="form-dialog-content">
    <!-- //Title filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Title<span class="star">*</span>
          <app-popover id="4"></app-popover>
        </mat-label>
      </div>

      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea matInput formControlName="title" class="texta"></textarea>
        <mat-error
          class="error"
          *ngIf="crossForm.controls?.['title']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Description filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Description
          <app-popover id="5"></app-popover>
        </mat-label>
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        appearance="outline"
      >
        <textarea
          class="texta"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
          matInput
          formControlName="description"
        >
        </textarea>
        <!-- <mat-error
          class="error"
          *ngIf="crossForm.controls?.['description']?.errors?.['required']"
          >This field is mandatory</mat-error
        > -->
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box m-t" align="end">
          <!-- <button mat-button mat-dialog-close>Cancel</button> -->

          <div class="risk-report-button-box">
            <button
              class="risk-report-button-box__btn btn--chestnut"
              type="submit"
              mat-raised-button
              mat-button
              color="primary"
              (click)="submit()"
              cdkFocusInitial
            >
              <mat-icon
                *ngIf="data.id === 'add'; else edit"
                class="create-risk-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M11.5556 0H1.44444C0.642778 0 0 0.65 0 1.44444V11.5556C0 12.35 0.642778 13 1.44444 13H11.5556C12.35 13 13 12.35 13 11.5556V1.44444C13 0.65 12.35 0 11.5556 0ZM11.5556 11.5556H1.44444V1.44444H11.5556V11.5556ZM5.77778 10.1111H7.22222V7.22222H10.1111V5.77778H7.22222V2.88889H5.77778V5.77778H2.88889V7.22222H5.77778V10.1111Z"
                    fill="white"
                  />
                </svg>
              </mat-icon>
              <ng-template #edit>
                <mat-icon class="create-risk">
                  <span class="material-symbols-outlined"> edit_note </span>
                </mat-icon>
              </ng-template>
              {{ data.id === "add" ? "Add" : "Save" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
