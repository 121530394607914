<div mat-dialog-container class="contact-box">
  <!-- <div class="form-dialog-header delete-header">
    <div class="form-dialog-header__text-box">
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title == 'Logout'"
      >
        Logout
      </p>
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title != 'Send to all users' || data.title != 'Logout'"
      >
        Delete
      </p>
      <p
        class="form-dialog-header__text-box__text"
        *ngIf="data.title == 'Send to all users'"
      >
        {{ data.title }}
      </p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title == 'Logout'">Logout</p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title != 'Send to all users' && data.title != 'Logout'">Delete</p>
      <p class="form-dialog-header__text-box__text" *ngIf="data.title == 'Send to all users'">{{data.title}}</p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button class="form-dialog-header__icon-box__btn" (click)="closeDialog()">
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div> -->

  <div class="contact-content-box">
    <div class="el-1"></div>
    <div class="el-2">
      <div class="el-2-box">
        <div class="close-box">
          <button
            class="form-dialog-header__icon-box__btn"
            (click)="onCloseDialog()"
          >
            <!-- <mat-icon class="form-dialog-header__icon-box__icon-size">
              <img
                class="contact-dialog-header__icon"
                src="../../../assets/shared-image/Vector-close.svg"
                alt="~/Frontend/images/icon _ Vector-close"
              />
            </mat-icon> -->
          </button>
        </div>

        <div>
          <p class="contact-text-main">Please do not hesitate to contact us:</p>
        </div>
        <div class="contact-text-box">
          <p class="contact-text-box__text">
            For technical questions related with this tool, please contact

            <a
              class="contact-text-box__link"
              href="mailto:PRMSTechSupport@cgiar.org"
            >
              PRMSTechSupport@cgiar.org &rarr;</a
            >
          </p>
        </div>
        <div class="contact-text-box">
          <p class="contact-text-box__text">
            For content questions related on how to fill different sections,
            please contact
            <a
              class="contact-text-box__link"
              href="mailto:projectcoordinationunit@cgiar.org"
            >
              projectcoordinationunit@cgiar.org &rarr;</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="el-3"></div>
  </div>

  <!-- <mat-dialog-actions>
    <button
      class="delete-dialog-button-box__btn"
      mat-raised-button
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      Yes
    </button>

    <button
      class="delete-dialog-button-box__btn--no"
      mat-raised-button
      [mat-dialog-close]="false"
    >
      No
    </button>
  </mat-dialog-actions> 
 -->
</div>
