<div mat-dialog-container class="phase-box">
  <div class="form-dialog-header">
    <div class="form-dialog-header__text-box">
      <p class="form-dialog-header__text-box__text">
        {{ phaseId ? "Edit" : "Add" }} Phase
      </p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button
        class="form-dialog-header__icon-box__btn"
        (click)="onCloseDialog()"
      >
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- <h1 mat-dialog-title>{{ phaseId ? "Edit" : "Add" }} Phase</h1> -->

  <div class="form-dialog-conten-box-1">
    <mat-dialog-content>
      <form [formGroup]="phaseForm" class="form-dialog-content">
        <!-- //Name  filed----- -->
        <div class="form-dialog-content__field--grid-column">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Name<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input matInput formControlName="name" />
            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['name']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
          </mat-form-field>
        </div>

        <!-- //Reporting year  filed----- -->

        <div class="form-dialog-content__field--grid-column">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Reporting year<span class="star">*</span></mat-label
            >
          </div>

          <mat-form-field
            class="form-dialog-content__field"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input
              matInput
              type="number"
              formControlName="reportingYear"
              pattern="^\d{4}$"
              #reportingYear
            />
            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['reportingYear']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
          </mat-form-field>
        </div>

        <!-- //ToC Phase  filed----- -->

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >ToC Phase<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <mat-select formControlName="tocPhase">
              <mat-option
                *ngFor="let tocPhase of tocPhases"
                [value]="tocPhase.id"
              >
                {{ tocPhase.name }}
              </mat-option>
            </mat-select>

            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['tocPhase']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
          </mat-form-field>
        </div>

        <!-- //Start date  filed----- -->

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Start date<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input
              matInput
              [matDatepicker]="startDatePicker"
              formControlName="startDate"
            />

            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['startDate']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="startDatePicker">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- //End date  filed----- -->
        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >End date<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input
              matInput
              [matDatepicker]="endDatePicker"
              formControlName="endDate"
            />

            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['endDate']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="endDatePicker">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- //Previous Phase  filed----- -->
        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Previous Phase<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <mat-select formControlName="previousPhase">
              <ng-container *ngFor="let phase of phases">
                <mat-option [value]="phase.id">
                  {{ phase.name }}
                </mat-option>
              </ng-container>
            </mat-select>

            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['previousPhase']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
          </mat-form-field>
        </div>

        <!-- //Status  filed----- -->

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Status<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <mat-select formControlName="status">
              <mat-option value="open">Open</mat-option>
              <mat-option value="closed">Closed</mat-option>
            </mat-select>

            <mat-error
              class="error"
              *ngIf="phaseForm.controls?.['status']?.errors?.['required']"
              >This field is mandatory</mat-error
            >
          </mat-form-field>
        </div>

        <section>
          <mat-checkbox formControlName="show_eoi" color="primary"
            >Show EOI</mat-checkbox
          >
        </section>

        <div>
          <div class="form-dialog-conten-button-box">
            <div class="form-dialog-conten__button-box" align="end">
              <!-- <button mat-button mat-dialog-close>Cancel</button> -->
              <div class="risk-report-button-box">
                <button
                  class="risk-report-button-box__btn btn--chestnut"
                  type="submit"
                  mat-raised-button
                  mat-button
                  color="primary"
                  (click)="submit()"
                >
                  {{ phaseId ? "Save" : "Add" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <!-- <mat-dialog-actions align="end"> -->
    <!-- <button mat-button mat-dialog-close>Cancel</button> -->
    <!-- </mat-dialog-actions> -->
  </div>
</div>
