<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="user-management__icon-box__icon">
    <svg
      class="cc"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19 11q-.95 0-2.025-.537t-2-1.488q-.925-.95-1.462-2.025t-.538-2q0-.425.125-.775t.375-.6q.65-.65 2.638-1.138t3.737-.412q.625.05 1.038.15t.612.3q.175.175.288.537t.162.913q.125 1.725-.35 3.8t-1.15 2.75q-.25.25-.637.388T19 11Zm.875-5q.05-.45.075-.95T20 4q-.5-.025-1.012.013t-1.013.087q.275.2.537.425t.488.45q.25.25.463.5t.412.525ZM19 9q.025-.5-.375-1.238T17.575 6.4q-.625-.625-1.338-1.012T15.026 5q.05.575.425 1.3t.925 1.275q.6.6 1.313.988T19 9Zm2.3 9.7L16.6 14h-10L1.3 8.7l1.4-1.4L7.4 12h10l5.3 5.3l-1.4 1.4ZM8 22v-4q0-.825.588-1.413T10 16h4q.825 0 1.413.588T16 18v4H8Z"
        fill="black"
      />
    </svg>
  </mat-icon>
  <span class="user-management__icon-box__title">Phases Initiatives</span>
</div>

<div class="user-management__text-box--1">
  <p class="user-management__text-box__title">
    Welcome to the phase initiatives panel, here you can manage the phase
    initiatives table within the platform.
  </p>
</div>

<nav class="nav-tab-main">
  <ul class="nav-tab-list">
    <li routerLink="/admin/phases">
      <a class="btn btn--outline">
        <span>Phases</span>
      </a>
    </li>
  </ul>
</nav>

<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="table-box mat-elevation-z8">
      <table mat-table matSort [dataSource]="dataSource">
        <tr
          class="table-header"
          mat-header-row
          *matHeaderRowDef="columnsToDisplay"
        ></tr>
        <tr
          class="table-header"
          mat-row
          *matRowDef="let myRowData; columns: columnsToDisplay"
        ></tr>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let initiative">
            {{ initiative.initiative_id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="official_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            official_code
          </th>
          <td mat-cell *matCellDef="let initiative">
            {{ initiative.initiative_official_code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let initiative">
            {{ initiative.initiative_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="organizations">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Assigned Organizations
          </th>
          <td mat-cell *matCellDef="let initiative">
            {{ initiative.assigned_organizations }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let initiative">
            <div class="m">
              <button
                class="user-management-table-icon"
                (click)="openDialog(initiative.initiative_id)"
                matTooltip="Assign Organizations"
              >
                <mat-icon class="user-management-table__icon">dns</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
      </table>
      <div class="glossary-content-container user-management-paginator-box">
        <mat-paginator
          [pageSizeOptions]="[10, 20, 30, 50, 100]"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
