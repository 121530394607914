<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ periodId ? "Edit" : "Add" }} Period
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <form [formGroup]="periodForm" class="form-dialog-content">
    <!-- //Phase  filed----- -->
    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Phase<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select formControlName="phase">
          <mat-option *ngFor="let phase of phases" [value]="phase.id">
            {{ phase.name }}
          </mat-option>
        </mat-select>

        <mat-error
          class="error"
          *ngIf="periodForm.controls?.['phase']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Year filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Year<span class="star">*</span></mat-label
        >
      </div>

      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input
          matInput
          type="number"
          formControlName="year"
          pattern="^\d{4}$"
          #year
        />
        <mat-error
          class="error"
          *ngIf="periodForm.controls?.['year']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Quarter filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Quarter<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="quarter" />
        <mat-error
          class="error"
          *ngIf="periodForm.controls?.['quarter']?.errors?.['required']"
          >This field is mandatory</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box" align="end">
          <!-- <button mat-button mat-dialog-close>Cancel</button> -->
          <div class="risk-report-button-box">
            <button
              class="risk-report-button-box__btn btn--chestnut"
              type="submit"
              mat-raised-button
              mat-button
              color="primary"
              (click)="submit()"
            >
              {{ periodId ? "Save" : "Add" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- 
<mat-dialog-actions align="end"> -->
<!-- <button mat-button mat-dialog-close>Cancel</button> -->
<!-- </mat-dialog-actions> -->
