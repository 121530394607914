<div mat-dialog-container class="phase-box">
  <div class="form-dialog-header">
    <div class="form-dialog-header__text-box">
      <p class="form-dialog-header__text-box__text">IPSR Values</p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button
        class="form-dialog-header__icon-box__btn"
        (click)="onCloseDialog()"
      >
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div>

  <div class="form-dialog-conten-box-1">
    <mat-dialog-content>
      <form [formGroup]="ipsrForm" class="form-dialog-content">
        <!-- //Title filed----- -->
        <div class="form-dialog-content__field--grid-column">
          <div
            class="form-dialog-content__title-box"
            *ngFor="let item of ipsrs"
          >
            <mat-label
              class="form-dialog-content__title-box__title"
              *ngIf="!item.need_in_description"
              >{{ item.title }} - Add value
              <app-popover>
                {{ item.description }}
              </app-popover>
            </mat-label>

            <mat-label
              class="form-dialog-content__title-box__title"
              *ngIf="item.need_in_description"
              >{{ item.title }} - Description and value
              <app-popover>
                {{ item.description }}
              </app-popover>
            </mat-label>

            <mat-form-field
              class="form-dialog-content__field"
              floatLabel="always"
              hideRequiredMarker
              appearance="outline"
              required
            >
              <input
                matInput
                type="text"
                placeholder="value"
                (ngModelChange)="change(item)"
                [formControlName]="'value-' + item.id"
              />
            </mat-form-field>
            <mat-error
              class="error"
              *ngIf="ipsrForm?.errors?.['value'+ item.id]"
              >You can insert the values and range (X-Y) only.</mat-error
            >

            <ng-container *ngIf="item.need_in_description">
              <mat-form-field
                class="form-dialog-content__field"
                floatLabel="always"
                hideRequiredMarker
                appearance="outline"
              >
                <textarea
                  [required]="
                    ipsrForm.controls['value-' + item.id].value != '' &&
                    ipsrForm.controls['value-' + item.id].value != null
                  "
                  matInput
                  type="text"
                  [formControlName]="'description-' + item.id"
                  placeholder="Description"
                  (ngModelChange)="change(item)"
                  >
                </textarea>
              </mat-form-field>
              <mat-error
                class="error"
                *ngIf="ipsrForm?.errors?.['descriptionRequired'+ item.id]"
                >This field is mandatory</mat-error
              >
            </ng-container>
          </div>
        </div>
        <div>
          <div class="form-dialog-conten-button-box">
            <div class="form-dialog-conten__button-box m-t" align="end">
              <!-- <button mat-button mat-dialog-close>Cancel</button> -->
              <div class="risk-report-button-box">
                <button
                  class="risk-report-button-box__btn btn--chestnut"
                  type="submit"
                  mat-raised-button
                  mat-button
                  color="primary"
                  (click)="submit()"
                  cdkFocusInitial
                >
                  <mat-icon class="create-risk">
                    <span class="material-symbols-outlined">
                      data_saver_on
                    </span>
                  </mat-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>
</div>
